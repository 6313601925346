import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { Platform, ToastController } from '@ionic/angular';
import { UserService } from './core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Дома',
      url: '/',
      icon: 'home'
    },
    {
      title: 'Контролни пописи',
      url: '/kontrolnipopisi',
      icon: 'calendar'
    },
    {
      title: 'Профактури',
      url: '/profakturi',
      icon: 'calendar'
    },    
    {
      title: 'Нарачки',
      url: '/naracki',
      icon: 'calendar'
    },    
    {
      title: 'Дневни статистики',
      url: '/daystats',
      icon: 'cash'
    },  
    {
      title: 'Аванси и доплати',
      url: '/avansi',
      icon: 'alarm'
    },        
    {
      title: 'За апликацијата',
      url: '/about',
      icon: 'information-circle'
    }
  ];
  isAuthenticated: boolean;
  
  constructor(
    private platform: Platform,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private userService: UserService,
    public router: Router
  ) {
    this.initializeApp();
  }

  async ngOnInit() {

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Има нова верзија!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });

    this.userService.populate();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.userService.isAuthenticated.subscribe(
        (authenticated) => {
          this.isAuthenticated = authenticated;
  
          // set the article list accordingly
          if (authenticated) {
            this.isAuthenticated = true;
          } else {
            this.isAuthenticated = false;
            this.router
            .navigateByUrl('/login', { replaceUrl: true })
          }
        }
      );
        
      this.userService.populate();
  
    });
  }

  logout() {
    this.userService.purgeAuth();
  }



}
