import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { UserService } from './user.service';

import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Mat, Arh } from '../models';

@Injectable()
export class DataService {
  oe: any[];
  dobav: any[];
  
  constructor (
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        if (authenticated) {
          this.getOe().subscribe((data) => {
            this.oe = data;
          });
          this.getDobav().subscribe((data) => {
            this.dobav = data;
          });          
        } else {
        }
      });
    }

  getDocs(params: HttpParams): Observable<any[]> {
    return this.apiService.get(`/matlist`,params)
      .pipe(map(data => data.docs));
  }
  
  getWeekStats(params: HttpParams): Observable<any[]> {
    return this.apiService.get(`/weekstat`,params)
      .pipe(map(data => data.rows));
  }

  getDayStats(params: HttpParams): Observable<any[]> {
    return this.apiService.get(`/daystat`,params)
      .pipe(map(data => data.stats));
  }

  getAvansi(params: HttpParams): Observable<any[]> {
    return this.apiService.get(`/avansi`,params)
      .pipe(map(data => data.avansi));
  }

  getAvansiStats(params: HttpParams): Observable<any> {
    return this.apiService.get(`/avansistat`,params)
      .pipe(map(data => data));
  }

  getMat(params: HttpParams): Observable<Mat> {
    return this.apiService.get(`/mat`,params)
      .pipe(map(data => data));
  }

  getOe(): Observable<any[]> {
    return this.apiService.get(`/oe`)
      .pipe(map(data => data.oe));
  }

  getDobav(): Observable<any[]> {
    return this.apiService.get(`/dobav`)
      .pipe(map(data => data.rows));
  }
  setZaliha(row: any): Observable<Arh> {
      return this.apiService.post('/arh/setzaliha', row)
        .pipe(map(data => data));
    }
    
    newDoc(doc: any): Observable<any> {
      return this.apiService.post('/mat/newdoc', doc)
        .pipe(map(data => data));
    }   

}
