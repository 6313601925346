import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'avansi',
    loadChildren: () => import('./pages/avansi/avansi.module').then(m => m.AvansiModule)
  },
  {
    path: 'avansistats/:oe/:datum1/:datum2',
    loadChildren: () => import('./pages/avansistats/avansistats.module').then(m => m.AvansiStatsModule)
  },
  {
    path: 'daystats',
    loadChildren: () => import('./pages/daystats/daystats.module').then(m => m.DayStatsModule)
  }, {
    path: 'weekstats/:oe',
    loadChildren: () => import('./pages/weekstats/weekstats.module').then(m => m.WeekStatsModule)
  },
  {
    path: 'kontrolnipopisi',
    loadChildren: () => import('./pages/kontrolnipopisi/kontrolnipopisi.module').then(m => m.KontrolniPopisiPageModule)
  },
  {
    path: 'profakturi',
    loadChildren: () => import('./pages/profakturi/profakturi.module').then(m => m.ProfakturiPageModule)
  },
  {
    path: 'naracki',
    loadChildren: () => import('./pages/naracki/naracki.module').then(m => m.NarackiPageModule)
  },
  {
    path: 'naracka/:slug',
    loadChildren: () => import('./pages/naracka/naracka.module').then(m => m.NarackaPageModule)
  },
  {
    path: 'kontrolenpopis/:slug',
    loadChildren: () => import('./pages/kontrolenpopis/kontrolenpopis.module').then(m => m.KontrolenPopisPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
